<template>
  <div v-if="application" class="nav-mobile-details-wrapper">
    <nav class="nav-mobile-details">
      <BaseButtonIconArrowBack
        @click="$router.back()"
        :size="'small'"
        class="button-icon nav-mobile-details__button-icon"
      />
      <ButtonCtaCall
        v-if="!isArchived"
        @click="openPopupCall"
        :size="'x-small'"
      />
      <BaseButtonIconWishlist
        v-if="!isArchived"
        @click="handleWishlistToggler"
        :size="'small'"
        :is-wishlisted="isWishlisted"
        :class="[
          'button-icon',
          'nav-mobile-details__button-icon',
          isWishlisted ? 'wishlisted' : '',
        ]"
      />
    </nav>

    <ApplicationNannyDetailsPopupCall
      v-if="canShowPopupCall"
      ref="popupCallRef"
      :nanny-phone="application.user_profile_id_populated.phone"
      :nanny-name="application.user_profile_id_populated.nameFirst"
    />

    <PopupPushToRegistration
      v-if="isAuth === false"
      ref="popupPushToRegistrationRef"
      :description="popupPushToRegDescription"
    />
  </div>
</template>

<script>
import useAuth from "@/composables/useAuth";
import useApplicationNannyDetails from "@/composables/useApplicationNannyDetails";
import { useRoute } from "vue-router";

import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";
import BaseButtonIconWishlist from "@/components/UI/Base/BaseButtonIconWishlist.vue";
import ButtonCtaCall from "@/components/UI/ButtonCtaCall.vue";
import ApplicationNannyDetailsPopupCall from "@/components/Application/Nanny/Details/ApplicationNannyDetailsPopupCall.vue";
import PopupPushToRegistration from "@/components/PopupPushToRegistration.vue";

export default {
  components: {
    ButtonCtaCall,
    BaseButtonIconArrowBack,
    BaseButtonIconWishlist,
    ApplicationNannyDetailsPopupCall,
    PopupPushToRegistration,
  },

  setup() {
    const { isAuth } = useAuth();
    const route = useRoute();
    const {
      isWishlisted,
      popupPushToRegistrationRef,
      application,
      popupCallRef,
      openPopupCall,
      canShowPopupCall,
      handleWishlistToggler,
      popupPushToRegDescription,
      isArchived,
    } = useApplicationNannyDetails(route.params.id);

    return {
      isAuth,
      isArchived,
      isWishlisted,
      application,
      popupCallRef,
      openPopupCall,
      canShowPopupCall,
      popupPushToRegistrationRef,
      handleWishlistToggler,
      popupPushToRegDescription,
    };
  },
};
</script>

<style scoped>
.nav-mobile-details-wrapper {
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
.nav-mobile-details {
  width: var(--layout-default--width);
  height: 50px;

  margin: 0 auto;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile-details__button-icon {
  width: calc((100% - 3 * 10px) / 4);
  opacity: 0.4;
}
.nav-mobile-details__button-icon:active,
.nav-mobile-details__button-icon.wishlisted {
  opacity: 1;
}

/* .nav-mobile-details__button {
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.4;
}
.nav-mobile-details__button.router-button-active {
  opacity: 1;
} */
</style>
